<template>

    <div class="contenedor">
      <h2>TECNOLOGÍAS EN DESARROLLO</h2>
      
      <div class="tecnos-grid">
        <div v-for="tec in tecnologias" :key="tec.id" class="tecnologia">
          <h3>{{ tec.name }}</h3>
          <router-link :to="tec.link" @click="navigate(tec.component)">
            <div :class="['icon-container', tec.colorClass]">
              <i :class="tec.iconClass"></i>
            </div>
          </router-link>
        </div>
      </div>

      <div class="logx3">
        <img src="@/assets/AR/img8.png" alt="logo">
      </div>

    </div>
</template>
  
<script>
  export default {
    name: 'AramCont',
    data() {
      return {
        tecnologias: [
          { id: 2, name: 'CISTEMERROR:', link: '/Ce', iconClass: 'bi bi-stack', colorClass: 'color-black' },
          { id: 3, name: 'HOUSE OF HYDRAS', link: '/HoH', iconClass: 'bi bi-h-circle', colorClass: 'color-white' },
          { id: 4, name: 'R&L', link: '#', iconClass: 'bi bi-rocket', colorClass: 'color-black' },
          { id: 5, name: 'P.M', link: '#', iconClass: 'bi bi-magnet-fill', colorClass: 'color-white' },
          { id: 6, name: 'Q.R', link: '#', iconClass: 'bi bi-box', colorClass: 'color-black' },
        ],
      };
    },
    methods: {
      navigate(component) {
        this.$emit('navigate', component);
      },
    },
  };
</script>
  
<style scoped>
.contenedor {
  align-items: center;
  text-align: center;
}
h3 {
  font-family: "Chakra Petch";
  font-weight: lighter;
}
.tecnos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 1em;
  margin-top: 2em;
}
.tecnologia {
  text-align: center;
}
.icon-container {
  font-size: 5em;
  padding: 25px;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  border-radius: 50%;
  background-color: #FEF9E6;
  border: 2px solid #060606;
  transition: color 0.3s ease, background-color 0.3s ease, transform 0.3s ease, border-color 0.3s ease;
}
.icon-container.color-white {
  background-color: #FEF9E6;
  border-color: #060606;
  color: #060606;
} 
.icon-container.color-black {
  background-color: #060606;
  border-color: #FEF9E6;
  color: #FEF9E6;
}
.icon-container:hover {
  color: #FEF9E6;
  background-color: #060606;
  border-color: #FEF9E6;
  transform: translateY(-5px);
} 
.icon-container.color-black:hover {
  color: #060606;
  background-color: #FEF9E6;
  border-color: #060606;
  transform: translateY(-5px);
}
.logx3 {
  display: flex;
  justify-content: center;
  margin-top: 3em;
}
.logx3 img {
  max-width: 50%;
  height: auto;
  object-fit: cover;
  transition: filter 1s ease;
}
.logx3 img:hover {
filter: hue-rotate(200deg);
}
</style>