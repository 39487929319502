import { createRouter, createWebHistory } from 'vue-router';
import InicioCont from './components/InicioCont.vue';
import LibroCont from './components/LibroCont.vue';
import DestacadosCont from './components/DestacadosCont.vue';
import AramCont from './components/AramCont.vue';
import CistemerrorCont from './components/CistemerrorCont.vue';
import GnulinuxargCont from './components/GnulinuxargCont.vue';
import HoHCont from './components/HoHCont.vue';

const routes = [
  {
    path: '/',
    name: 'InicioCont',
    component: InicioCont,
    props: true,
  },
  {
    path: '/libro',
    name: 'LibroCont',
    component: LibroCont,
  },
  {
    path: '/aram',
    name: 'AramCont',
    component: AramCont,
  },
  {
    path: '/Ce',
    name: 'CistemerrorCont',
    component: CistemerrorCont,
  },
  {
    path: '/HoH',
    name: 'HoHCont',
    component: HoHCont,
  },
  {
    path: '/gla',
    name: 'GnulinuxargCont',
    component: GnulinuxargCont,
  },
  {
    path: '/destacado/:id',
    name: 'DestacadosCont',
    component: DestacadosCont,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;