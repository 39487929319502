<template>
    <div class="libro-cont">

      <div class="libro-abierto">
        <h2>LA - D5AR: D&P [NIIPLRDDSXXI]</h2>

        <div class="capitulo">
          <h3>Capítulo I: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>Capítulo II: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>Capítulo III: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>Capítulo IV: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>Capítulo V: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

      </div>
    </div>
  </template>

  <script>
  export default {
    name: 'LibroCont',
  };
  </script>

  <style scoped>
h3 {
  color: #4a4843;
  font-family: "Lora";
}
.highlight {
  color: #4a4843;
}
</style>