<template>
    <div class="hoh-container">
      <div class="info">
        <div class="logoh">
          <h1>HOUSE OF HYDRAS</h1>
        </div>
  
        <nav class="navbar"></nav>
  
        <div class="descripcion">
          <h2>#CasaDeHidras</h2>
          <p>
            H.O.H es una plataforma de comercio solidario que conecta a personas que desean comprar o vender productos y servicios con aquellas que necesitan asistencia. A través de esta plataforma, los participantes pueden contribuir a una acción solidaria al donar libremente sus productos o parte de sus transacciones para apoyar a personas en situación de vulnerabilidad. A cambio, obtienen visibilidad y promoción.
          </p>
        </div>
  
        <div class="fundamentacion">
          <h3>Fundamentación</h3>
          <p>
            En un contexto de profunda crisis económica, desigualdad social y ausencia del estado, es crucial desarrollar redes y vínculos solidarios que contrarresten el modelo de exclusión imperante. H.O.H surge como una respuesta innovadora y efectiva para enfrentar estas problemáticas. En lugar de depender de estructuras tradicionales que a menudo perpetúan la desigualdad, nuestra plataforma fomenta un comercio solidario que crea conexiones directas entre quienes necesitan ayuda y quienes están dispuestxs a ofrecerla. Este enfoque no solo alivia las carencias inmediatas de las personas en situación de vulnerabilidad, sino que también fortalece el tejido social al promover la colaboración y el apoyo mutuo en tiempos difíciles.
          </p>
        </div>
  
        <div class="valor-agregado">
          <h3>Valor Agregado</h3>
          <ul>
            <li><span>Impacto Social:</span> Mejora la calidad de vida de personas en situación de vulnerabilidad a través de la acción solidaria.</li>
            <li><span>Visibilidad:</span> Incrementa la exposición de los productos y servicios ofrecidos en la plataforma.</li>
            <li><span>Comunidad:</span> Fortalece una red de apoyo y solidaridad en tiempos de crisis.</li>
            <li><span>Transparencia:</span> Asegura un proceso claro y accesible para todos los participantes, sumando voluntarixs que colaboren en la verificación de necesidades y el impacto de las donaciones.</li>
          </ul>
        </div>
  
        <div class="como-funciona">
          <h3>¿Cómo Funciona?</h3>
          <p>
            La plataforma permite que las personas interesadas en comprar o vender productos y servicios se presenten y especifiquen el alcance de sus ofertas o promociones. Al mismo tiempo, los beneficiarixs describen sus necesidades específicas. La plataforma empareja a donantes con beneficiarixs teniendo en cuenta la ubicación y el tipo de ayuda requerida. Los vendedores entregan sus donaciones a los beneficiarixs, de forma directa o por medio de H.O.H, y el proceso se documenta para garantizar la transparencia. Además, los beneficiarixs se comprometen a sumar suscriptores o compartir sus experiencias en redes sociales, mientras que la plataforma genera y difunde contenido visual para ampliar y promover el comercio solidario.
          </p>
        </div>
  
        <div class="invitacion">
          <h3>Unite a nosotrxs.</h3>
          <p>
            Te invitamos a ser parte de esta inicitiva solidaria y transformadora. Ya sea que quieras comprar, vender, o simplemente apoyar, tu participación marca una diferencia significativa. Al unirte a H.O.H, no solo contribuirás a mejorar la vida de personas en situación de vulnerabilidad, sino que también formarás parte de una comunidad que valora la colaboración y el apoyo mutuo.
          </p>
        </div>
  
        <div class="button5">
          <button onclick="window.open('https://www.facebook.com/share/g/DkLQysiPr21LGti2/', '_blank')" class="btn" aria-label="Comunidad">
            ENLACE A LA COMUNIDAD
          </button>
        </div>
      </div>
  
      <div class="mensaje">
        <h4>@ A R A M _ V E R S E</h4>
      </div> 
    </div>
  </template>
  
  <script>
  export default {
    name: 'HoHCont',
  };
  </script>
  
  <style scoped>
  .navbar {
    background-color: #305252;
    padding: 10px;
    font-size: 1em;
  }
  .navbar ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  .navbar li {
    margin-right: 20px;
    margin-left: 20px;
  }
  .valor-agregado li {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  a {
    color: #060606;
    transition: color 1s ease;
  }
  span {
    font-weight: bold;
    color: #305252;
  }
  h1 {
    font-size: 2rem;
    color: #305252;
    margin-bottom: 1em;
    transition: color 1s ease;
  }
  h1:hover {
    color: #305252;
  }
  h2 {
    font-size: 1.5em;
    margin: 1.5em 0;
    font-family: "Chakra Petch";
  }
  h3 {
    font-size: 1.5em;
    margin: 1.5em 0;
    font-family: "Chakra Petch";
    font-weight: bold;
  }
  .descripcion, .fundamentacion, .valor-agregado, .como-funciona, .invitacion {
    margin-bottom: 2rem;
  }
  .button5 {
    margin: 0;
    margin-top: 3em;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  .button5 button {
    text-decoration: none;
    color: #060606;
    font-size: 1.5em;
    font-family: "Chakra Petch";
    transition: color 1s ease;
    background-color: #FEF9E6;
    border: 1.5px solid #060606;
    border-radius: 5px;
    padding: 15px 15px;
    margin: 5px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .button5 button:hover {
    background-color: #305252;
    color: #FEF9E6;
  }
  button:active {
    transform: scale(0.95);
  }
  .mensaje {
    overflow: hidden;
    margin-top: 3em;
  }
  .mensaje h4 {
    font-size: 1em;
    font-family: "Chakra Petch";
    text-decoration: none;
    color: #333633;
    animation: desplazamiento-horizontal 30s linear infinite;
  }
  @keyframes desplazamiento-horizontal {
    0% {
      transform: translateX(101%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  @media (max-width: 768px) {
    .button5 button {
      font-size: 1em;
      padding: 10px 10px;
    }
  }
  @media (max-width: 480px) {
    .button5 button {
      font-size: 0.7em;
      padding: 12px 12px;
    }
  }
  </style>  