<template>
  <div class="cistem-error">

    <div class="info">

      <div class="logoc3">
        <h1>CISTEMERROR</h1>
      </div>

      <nav class="navbar"></nav>

      <div class="quienes-somos">
        <h2>EDUCACIÓN COMUNITARIA NO FORMAL NO CONVENCIONAL:</h2>
        <p>Cistemerror (C3) es un espacio educativo comprometido con la expansión del conocimiento y la provisión de herramientas prácticas para mejorar la vida cotidiana. Su función es construir un entorno virtual y libre donde la educación sea un proceso inclusivo y accesible para todxs.</p>
      </div>

      <div class="por-que-elegirnos">
        <h3>¿Por qué Cistemerror?</h3>
        <p>En Cistemerror, el aprendizaje es un viaje continuo donde los errores son oportunidades para crecer. Las imperfecciones del sistema son puntos de partida para un aprendizaje significativo y una evolución constante.</p>
        <h2>EN CISTEMERROR NOS EQUIVOCAMOS, APRENDEMOS Y DESARROLLAMOS EL #SERFUTURO.</h2>
        <ul>
          <li><span>Metodología innovadora: </span>Contenidos interactivos y dinámicos que permiten aprender a cualquier ritmo.</li>
          <li><span>Comunidad vibrante: </span>Un ámbito para conectar con otras personas y compartir experiencias.</li>
          <li><span>Enfoque práctico: </span>Herramientas y habilidades para aplicar en la vida diaria.</li>
        </ul>
      </div>

      <div class="que-ofrecemos">
        <h3>El espacio se organiza en torno a 5 ejes fundamentales:</h3>
        <ul>
          <li><span>Trabajo: </span>Desarrollar habilidades y conocimientos para desenvolverse con éxito en el ámbito laboral.</li>
          <li><span>Estudio: </span>Brindar herramientas para optimizar el proceso de aprendizaje y la investigación.</li>
          <li><span>Consumo: </span>Formar consumidores críticxs y responsables que tomen decisiones informadas.</li>
          <li><span>Salud: </span>Promover hábitos saludables y fomentar el cuidado del cuerpo y la mente.</li>
          <li><span>Desarrollo: </span>Fortalecer las habilidades sociales y personales para sobrevivir en el mundo actual.</li>
        </ul>
      </div>

      <div class="button4">
        <button onclick="window.open('https://forms.gle/MWpxLWaHKkSn33Pr9', '_blank')" class="btn" aria-label="Formulario">
          FORMULARIO DE INSCRIPCIÓN
        </button>
      </div>
    </div>

    <div class="mensaje">
      <h4>@ A R A M _ V E R S E</h4>
    </div>

  </div>
</template>

<script>
export default {
  name: 'CistemErrorCont',
};
</script>

<style scoped>
.navbar {
  background-color: #b02610;
  padding: 10px;
  font-size: 1em;
}
.navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}
.navbar li {
  margin-right: 20px;
  margin-left: 20px;
}
a {
  color: #060606;
  transition: color 1s ease;
}
h1 {
  font-size: 2rem;
  color: #b02610;
  margin-bottom: 1em;
  transition: color 1s ease;
}
h1:hover {
  color: #b02610;
}
h2 {
  font-size: 1.5em;
  margin: 1.5em 0;
  font-family: "Chakra Petch";
}
h3 {
  font-size: 1.5em;
  margin: 1.5em 0;
  font-family: "Chakra Petch";
  font-weight: bold;
}
.button4 {
  margin: 0;
  margin-top: 3em;
  padding: 0;
  display: flex;
  justify-content: center;
}
.button4 button {
  text-decoration: none;
  color: #060606;
  font-size: 1.5em;
  font-family: "Chakra Petch";
  transition: color 1s ease;
  background-color: #FEF9E6;
  border: 1.5px solid #060606;
  border-radius: 5px;
  padding: 15px 15px;
  margin: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.button4 button:hover {
  background-color: #b02610;
  color: #FEF9E6;
}
button:active {
  transform: scale(0.95);
}
.mensaje {
  overflow: hidden;
  margin-top: 3em;
}
.mensaje h4 {
  font-size: 1em;
  
  font-family: "Chakra Petch";
  text-decoration: none;
  color: #333633;
  animation: desplazamiento-horizontal 30s linear infinite;
}
@keyframes desplazamiento-horizontal {
  0% {
    transform: translateX(101%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@media (max-width: 768px) {
  .button4 button {
    font-size: 1em;
    padding: 10px 10px;
  }
}
@media (max-width: 480px) {
  .button4 button {
    font-size: 0.7em;
    padding: 12px 12px;
  }
}
</style>