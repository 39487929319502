<template>
  <nav class="navbar" :class="{ scrolled: isScrolled }">
    <div class="nav-title">
      <router-link to="/">
        <h5>PORTFOLIO WEB</h5>
      </router-link>
    </div>
    <div class="logo-container">
      <div class="logo rotating">
        <router-link to="/">
          <img src="@/assets/AR/img6.png" alt="logo">
        </router-link>
      </div>
      <div class="logo">
        <router-link to="/">
        <img src="@/assets/AR/img10.png" alt="logo">
        </router-link>
      </div>
      <div class="logo aram">
        <router-link to="/aram">
          <img src="@/assets/AR/img13.png" alt="logo">
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      isScrolled: false,
    };
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 50;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>
.navbar {
  background-color: #020202;
  padding: 10px 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: padding 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}
.navbar.scrolled {
  padding: 5px 20px;
  background-color: rgb(2, 2, 2);
}
.nav-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.nav-title h5 {
  text-align: left;
  font-family: "Playfair Display", serif;
  margin: 0 5px 1.5em 10px;
  font-size: 1.2em;
  color: #FEF9E6;
  cursor: pointer;
}
.logo-container {
  display: flex;
  gap: 25px;
  flex-direction: row;
  padding: 5px;
  align-items: center;
  margin-bottom: 1em;
  margin-right: 10px;
}
.logo {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo img {
  max-width: 100%;
  max-height: 100%;
  transition: transform 0.3s ease;
  cursor: pointer;
}
.logo img:hover {
  transform: scale(1.15) rotate(10deg);
}
.logo.rotating img {
  animation: rotate 15s linear infinite;
}
.logo.aram img:hover {
  transform: scale(1.15);
  filter: grayscale(100%);
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .nav-title h5 {
    font-size: 1em;
  }
  .logo {
    width: 30px;
    height: 30px;
  }
}
@media (max-width: 480px) {
  .nav-title h5 {
    font-size: 0.9em;
  }
  .logo {
    width: 20px;
    height: 20px;
  }
}
</style>