<template>
  <div class="main-container">

    <div class="titulo">
      <div class="logx2">
        <img src="@/assets/AR/img7.png" alt="logo">
      </div>
      <h1>JULIETA CALDERON</h1>
      <h2>Abogada && FS Dev.</h2>
    </div>

    <div class="contenedor1">
      <h4>[ Asesoramiento y patrocinio jurídico ]</h4>
      <ul>
        <li><button>CONSTITUCIONAL</button></li>
        <li><button>ADMINISTRATIVO</button></li>
        <li><button>TRABAJO</button></li>
        <li><button>CONSUMO</button></li>
        <li><button>AMBIENTE</button></li>
        <li><button>INCIDENCIA COLECTIVA</button></li>
        <li><button>DISCRIMINACIÓN</button></li>
        <li><button>TECNOLOGÍA</button></li>
      </ul>
    <div class="button2">
      <h4>Para consultar sobre mis servicios o elegir las opciones disponibles:</h4>
      <button onclick="window.open('https://forms.gle/8sAhUefgFMZ87mNf9', '_blank')" class="btn" aria-label="Formulario">
       FORMULARIO DE CONSULTA
    </button>
    </div>
    </div>

    <div class="contenedor2">
      <h3>PROYECTOS NORMATIVOS</h3>
      <div class="destacados">
        <router-link v-for="project in proyectos" :key="project.id" :to="project.link" class="destacado-link">
          <div class="destacadx">
            <p>{{ project.name }}</p>
          </div>
        </router-link>
      </div>
    </div>

    <div class="contenedor3">
      <h3>COMUNIDAD GNU+LINUX*ARG</h3>
      <router-link to="/gla"  @click="navigate('GnulinxargCont')">
      <div class="button3">G+L*A</div>
    </router-link>
  </div>

    <div class="contenedor4">
      <h3>PRÓXIMAMENTE:</h3>
      <router-link to="/libro" @click="navigate('LibroCont')">
      <div class="logx">
          <img src="@/assets/AR/stories.png" alt="logo">
      </div>
    </router-link>
    </div>
  </div>

  <DestacadosCont v-if="selectedComponent === 'DestacadosCont'" />

</template>

<script>
import DestacadosCont from './DestacadosCont.vue';

export default {
  name: 'InicioCont',
  components: {
    DestacadosCont,
  },
  data() {
    return {
      proyectos: [
        { id: 12, name: 'PROGRAMA CRPD', link: '/destacado/13' },
        { id: 1, name: 'RECURSOS NATURALES', link: '/destacado/11' },
        { id: 2, name: 'ROBÓTICA', link: '/destacado/10' },
        { id: 3, name: 'DATOS BIOMÉTRICOS', link: '/destacado/9' },
        { id: 4, name: 'RETORNOS EDUCATIVOS', link: '/destacado/8' },
        { id: 5, name: 'DESARROLLO COMUNITARIO', link: '/destacado/7' },
        { id: 6, name: 'IBUA', link: '/destacado/6' },
        { id: 7, name: 'CRIPTOS', link: '/destacado/5' },
        { id: 8, name: 'EDUC. AMB. CONS.', link: '/destacado/4' },
        { id: 9, name: 'ACCIONES ESTRUCTURALES', link: '/destacado/2' },
        { id: 10, name: 'INTELIGENCIA ARTIFICIAL', link: '/destacado/3' },
        { id: 11, name: 'CN > 43 BIS/TER', link: '/destacado/1' },
      ],
    };
  },
  methods: {
    navigate(component) {
      this.selectedComponent = component;
      this.showContent = false;
    },
  },
};
</script>

<style scoped>
.main-container {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding: 0 1em;
  box-sizing: border-box; 
}
.titulo {
  margin-bottom: 0;
}
.titulo h1 {
  color: #060606;
  font-family: "Playfair Display";
  margin-bottom: 0.5em;
}

.titulo h2 {
  font-family: "Playfair Display";
  font-size: 1.1em;
  font-weight: lighter;
  margin: 5px 0;
  color: #060606;
}
.contenedor1, .contenedor2, .contenedor4 {
  width: 100%;
  max-width: 1200px;
  margin: 0 0 3em 0;
}
.contenedor1 h3, .contenedor2 h3, .contenedor3 h3, .contenedor4 h3 {
  font-family: "Lora";
  font-size: 1.3em;
  margin-bottom: 0.5em;
  font-weight: bold;
  color: #060606;
}
.contenedor1 h4, .contenedor2 h4, .contenedor4 h4 {
  font-family: "Playfair Display";
  margin: 5px 0 1.5em;
  font-size: 1.1em;
  font-weight: lighter;
  text-align: center;
  color: #060606;
}
.contenedor3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 3em 0;
}
ul {
  list-style: none;
  padding: 0;
  margin: 4em 0 3em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
li {
  margin: 5px;
}
.button2 button {
  background-color: #FEF9E6;
  font-family: "Chakra Petch";
  color: #060606;
  font-size: 1.5em;
  border: 1.5px solid #060606;
  border-radius: 5px;
  padding: 15px 15px;
  margin: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.button3 {
  background-color: #060606;
  font-family: "Chakra Petch";
  color: #FEF9E6;
  font-size: 3em;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #060606;
  border-radius: 50%;
  padding: 100px;
  margin: 5px;
  max-width: 100%;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
button {
  background-color: #FEF9E6;
  font-family: "Lora";
  color: #060606;
  border: 1px solid #060606;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.button3:hover {
  background-color: #0e1c36;
  color: #FEF9E6;
}
.button3:active {
  transform: scale(0.95);
}
button:hover {
  background-color: #666a86;
  color: #FEF9E6;
}
.button2 button:hover {
  background-color: #92b6b1;
  color: #FEF9E6;
}
button:active {
  transform: scale(0.95);
}
.destacados {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 1em;
  justify-content: center;
  margin: 1em auto;
  max-width: 100%;
  padding: 0 1em;
}
.destacadx {
  background-color: #060606;
  font-family: "Lora";
  color: #FEF9E6;
  border: 1px solid #060606;
  text-align: center;
  padding: 1em;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border-radius: 10px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
.destacadx p {
  font-size: 1em;
  margin: 0;
  padding: 0.5em;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis; 
  white-space: normal;
}
.destacadx h3 {
  margin-bottom: 0.5em;
}
.destacadx:hover {
  background-color: #788aa3;
  color: #060606;
}
.logx img {
  width: 100%;
  max-width: 800px;
  height: auto;
  margin-top: 2.5em;
  transition: filter 1s ease;
  box-shadow: 0px 0px 10px 1px #060606;
}
.logx img:hover {
  filter: grayscale(100%);
}
.logx2 img {
  width: 100%;
  max-width: 150px;
  margin-top: 3em;
  opacity: 95%;
  height: auto;
}
@media (max-width: 768px) {
  .button2 button {
    font-size: 1em;
    padding: 10px;
  }
  .button3 {
    font-size: 2em;
    padding: 75px;
  }
  .titulo h1 {
    font-size: 1.5em;
  }
  .titulo h2 {
    font-size: 1em;
  }
  .destacados {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-gap: 0.5em;
  }
}
@media (max-width: 480px) {
  .button2 button {
    font-size: 0.7em;
    padding: 10px;
  }
  .button3 {
    font-size: 1.5em;
    padding: 50px;
  }
}
</style>