<template>

  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css">
  <link rel="preconnect" href="https://fonts.googleapis.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600&family=Libre+Barcode+128&family=Lora:ital,wght@0,400..700;1,400..700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap" rel="stylesheet">
  
  <div class="main-container">

    <header>
      <NavBar @navItemSelected="handleNavItemSelection" />
      <InicioCont v-if="selectedComponent === 'InicioCont'" />
      <div class="content" v-if="showContent">
        <router-view />
      </div>
    </header>

    <main>
      <div>
        <LibroCont v-if="selectedComponent === 'LibroCont'" />
        <AramCont v-if="selectedComponent === 'AramCont'" />
        <CistemerrorCont v-if="selectedComponent === 'CistemerrorCont'" />
        <HoHCont v-if="selectedComponent === 'HoHCont'" />
        <GnulinuxargCont v-if="selectedComponent === 'GnulinuxargCont'" />
      </div>
    </main>

    <FooterCont/>

    <div class="banner"></div>

  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import InicioCont from './components/InicioCont.vue';
import LibroCont from './components/LibroCont.vue';
import FooterCont from './components/FooterCont.vue';
import AramCont from './components/AramCont.vue';
import CistemerrorCont from './components/CistemerrorCont.vue';
import HoHCont from './components/HoHCont.vue';
import GnulinuxargCont from './components/GnulinuxargCont.vue';

export default {
  name: 'App',
  components: {
    NavBar,
    InicioCont,
    LibroCont,
    FooterCont,
    AramCont,
    CistemerrorCont,
    HoHCont,
    GnulinuxargCont,
  },
  data() {
    return {
      selectedComponent: '',
      showContent: true
    }
  },
  methods: {
    handleNavItemSelection(component) {
      this.selectedComponent = component;
      this.showContent = false;
    }
  },
}
</script>

<style>
html, body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}
.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}
main {
  flex: 1;
}
.banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('') no-repeat center center;
  background-size: cover;
  opacity: 5%;
  z-index: -1; 
}
footer {
  z-index: 1;
}
</style>