<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="iconos">
        <a href="https://www.linkedin.com/in/julietxcalderon/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
          <i class="bi bi-linkedin"></i>
        </a>
        <a href="https://github.com/julietxcalderon" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
          <i class="bi bi-github"></i>
        </a>
        <a href="https://www.instagram.com/julietxcalderon" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
          <i class="bi bi-instagram"></i>
        </a>
      </div>

      <p>2024 JCVLENV // ARD</p>

    </div>
  </footer>

</template>

<style>
.footer {
  padding: 1em 2em;
  margin-top: 1em;
  background-color: #020202;
  color: #4A4348;
  text-align: center;
}
.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-content p {
  font-size: 1.5em;
  font-family: "Libre Barcode 128";
  opacity: 0.85;
  margin: 0.5em 0 0;
}
.iconos {
  display: flex;
  gap: 2em;
  margin-bottom: 1em;
}
.iconos a {
  color: #4A4348;
  transition: color 0.3s ease, transform 0.3s ease;
}
.iconos a:hover {
  color: #92b6b1;
  transform: scale(1.1);
}
.iconos i {
  font-size: 1.5em;
}
@media (max-width: 768px) {
  .footer-content {
    padding: 1em;
  }
  .iconos {
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.2em;
  }
  .iconos a {
    margin: 0.5em;
  }
}
</style>